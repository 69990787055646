// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util from "../../../common/util.bs.js";
import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Theme from "../../../css/Theme.bs.js";
import * as StaticImage from "../../StaticImage.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

var container = Curry._1(BsCss.style, {
      hd: BsCss.display("flex"),
      tl: {
        hd: BsCss.alignItems("center"),
        tl: {
          hd: BsCss.marginBottom(BsCss.px(28)),
          tl: /* [] */0
        }
      }
    });

var img = Curry._1(BsCss.style, {
      hd: BsCss.width(BsCss.px(24)),
      tl: {
        hd: BsCss.height(BsCss.px(24)),
        tl: {
          hd: BsCss.marginRight(BsCss.px(20)),
          tl: /* [] */0
        }
      }
    });

var bodyText = Curry._1(BsCss.style, {
      hd: Theme.Typography.defaultFonts,
      tl: {
        hd: BsCss.fontSize(BsCss.px(16)),
        tl: {
          hd: BsCss.lineHeight(BsCss.px(24)),
          tl: {
            hd: BsCss.color(Theme.Color.darkGrey),
            tl: {
              hd: BsCss.fontWeight("normal"),
              tl: {
                hd: BsCss.fontStyle("normal"),
                tl: {
                  hd: BsCss.media("(min-width: 768px) and (max-width: 1023px)", {
                        hd: BsCss.width(BsCss.px(215)),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: BsCss.media("(min-width: 1024px)", {
                          hd: BsCss.width(BsCss.px(215)),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var Styles = {
  container: container,
  img: img,
  bodyText: bodyText
};

function FeatureItem(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(StaticImage.make, {
                      src: props.iconSrc,
                      alt: props.iconDescription,
                      width: 24,
                      height: 24,
                      className: img
                    }),
                JsxRuntime.jsx("h4", {
                      children: Util.ReactStuff.s(props.bodyText),
                      className: bodyText
                    })
              ],
              className: container
            });
}

var make = FeatureItem;

export {
  Styles ,
  make ,
}
/* container Not a pure module */
