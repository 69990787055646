// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util from "../../../common/util.bs.js";
import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Theme from "../../../css/Theme.bs.js";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as ReviewCard from "./ReviewCard.bs.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as React from "swiper/react";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

var swiperBreakpoints = Js_dict.fromList({
      hd: [
        "0",
        {
          slidesPerView: 1.1,
          spaceBetween: 22,
          slidesPerGroup: 1
        }
      ],
      tl: {
        hd: [
          "320",
          {
            slidesPerView: 1.2,
            spaceBetween: 22,
            slidesPerGroup: 1
          }
        ],
        tl: {
          hd: [
            "400",
            {
              slidesPerView: 1.2,
              spaceBetween: 22,
              slidesPerGroup: 1
            }
          ],
          tl: {
            hd: [
              "440",
              {
                slidesPerView: 1.6,
                spaceBetween: 22,
                slidesPerGroup: 1
              }
            ],
            tl: {
              hd: [
                "480",
                {
                  slidesPerView: 1.2,
                  spaceBetween: 22,
                  slidesPerGroup: 1
                }
              ],
              tl: {
                hd: [
                  "520",
                  {
                    slidesPerView: 1.8,
                    spaceBetween: 22,
                    slidesPerGroup: 1
                  }
                ],
                tl: {
                  hd: [
                    "580",
                    {
                      slidesPerView: 1.8,
                      spaceBetween: 22,
                      slidesPerGroup: 1
                    }
                  ],
                  tl: {
                    hd: [
                      "740",
                      {
                        slidesPerView: 2.4,
                        spaceBetween: 22,
                        slidesPerGroup: 1
                      }
                    ],
                    tl: {
                      hd: [
                        "780",
                        {
                          slidesPerView: 2.8,
                          spaceBetween: 22,
                          slidesPerGroup: 1
                        }
                      ],
                      tl: {
                        hd: [
                          "820",
                          {
                            slidesPerView: 2.4,
                            spaceBetween: 22,
                            slidesPerGroup: 1
                          }
                        ],
                        tl: {
                          hd: [
                            "1000",
                            {
                              slidesPerView: 4.0,
                              spaceBetween: 22,
                              slidesPerGroup: 1
                            }
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var grayBgColor = Curry._1(BsCss.style, {
      hd: BsCss.position("absolute"),
      tl: {
        hd: BsCss.top(BsCss.pct(55.0)),
        tl: {
          hd: BsCss.bottom(BsCss.px(0)),
          tl: {
            hd: BsCss.right(BsCss.px(0)),
            tl: {
              hd: BsCss.left(BsCss.px(0)),
              tl: {
                hd: BsCss.backgroundColor(Theme.Color.paleGrey),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var wrapper = Curry._1(BsCss.style, {
      hd: BsCss.maxWidth(BsCss.px(1450)),
      tl: {
        hd: BsCss.marginLeft(BsCss.px(20)),
        tl: {
          hd: BsCss.media("(min-width: 768px) and (max-width: 1279px)", {
                hd: BsCss.marginLeft(BsCss.px(30)),
                tl: /* [] */0
              }),
          tl: {
            hd: BsCss.media("(min-width: 1280px) and (max-width: 1449px)", {
                  hd: BsCss.marginLeft(BsCss.px(30)),
                  tl: {
                    hd: BsCss.marginTop(BsCss.px(50)),
                    tl: /* [] */0
                  }
                }),
            tl: {
              hd: BsCss.media("(min-width: 1450px)", {
                    hd: BsCss.marginLeft("auto"),
                    tl: {
                      hd: BsCss.marginRight("auto"),
                      tl: {
                        hd: BsCss.marginTop(BsCss.px(50)),
                        tl: /* [] */0
                      }
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var container = Curry._1(BsCss.style, {
      hd: BsCss.backgroundImage({
            NAME: "url",
            VAL: "/images/downloadApps/quote_bg.svg"
          }),
      tl: {
        hd: BsCss.backgroundRepeat("noRepeat"),
        tl: {
          hd: BsCss.backgroundSize("auto"),
          tl: {
            hd: BsCss.position("relative"),
            tl: {
              hd: BsCss.minWidth(BsCss.px(288)),
              tl: {
                hd: BsCss.minHeight(BsCss.px(298)),
                tl: {
                  hd: BsCss.borderRadius(BsCss.px(8)),
                  tl: {
                    hd: BsCss.boxShadow(Css_Legacy_Core.Shadow.box(BsCss.px(4), BsCss.px(4), BsCss.px(25), undefined, undefined, BsCss.rgba(68, 68, 68, BsCss.pct(15.0)))),
                    tl: {
                      hd: BsCss.backgroundColor(Theme.Color.white),
                      tl: {
                        hd: BsCss.padding(BsCss.px(20)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var swiperContainer = Curry._1(BsCss.style, {
      hd: BsCss.paddingTop(BsCss.px(27)),
      tl: {
        hd: BsCss.paddingBottom(BsCss.px(27)),
        tl: {
          hd: BsCss.paddingLeft(BsCss.px(10)),
          tl: /* [] */0
        }
      }
    });

var headerText = Curry._1(BsCss.style, {
      hd: BsCss.position("relative"),
      tl: {
        hd: BsCss.color(BsCss.hex("444")),
        tl: {
          hd: BsCss.fontSize(BsCss.px(32)),
          tl: {
            hd: BsCss.fontWeight({
                  NAME: "num",
                  VAL: 600
                }),
            tl: {
              hd: BsCss.lineHeight(BsCss.px(40)),
              tl: {
                hd: BsCss.media("(min-width: 768px) and (max-width: 1023px)", {
                      hd: BsCss.paddingBottom(BsCss.px(16)),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var Styles = {
  grayBgColor: grayBgColor,
  wrapper: wrapper,
  container: container,
  swiperContainer: swiperContainer,
  headerText: headerText
};

function AppsReviewSection(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      className: grayBgColor
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h1", {
                              children: Util.ReactStuff.s("What our customers are saying"),
                              className: headerText
                            }),
                        JsxRuntime.jsxs(React.Swiper, {
                              breakpoints: Caml_option.some(swiperBreakpoints),
                              children: [
                                JsxRuntime.jsx(React.SwiperSlide, {
                                      className: container,
                                      children: JsxRuntime.jsx(ReviewCard.make, {
                                            bodyText: "Many of my family members live interstate and I use Prezzee to send gift vouchers. It's really cool the way you can upload a video and schedule the time for delivery of the voucher to the recipient.",
                                            titleText: "Great app for sending gift cards"
                                          })
                                    }),
                                JsxRuntime.jsx(React.SwiperSlide, {
                                      className: container,
                                      children: JsxRuntime.jsx(ReviewCard.make, {
                                            bodyText: "The app is super easy - you can swap out Prezzee gift vouchers for store gift cards with lots of choice. As a recipient, you can easily navigate, choose what vouchers you want at a click, even at the store counter when paying.",
                                            titleText: "Without a doubt the easiest way to send a gift card"
                                          })
                                    }),
                                JsxRuntime.jsx(React.SwiperSlide, {
                                      className: container,
                                      children: JsxRuntime.jsx(ReviewCard.make, {
                                            bodyText: "Huge variety of gift cards and so easy to use and I love how you can schedule a date for the card to be delivered. I will definitely be using Prezzee again",
                                            titleText: "So user friendly"
                                          })
                                    }),
                                JsxRuntime.jsx(React.SwiperSlide, {
                                      className: container,
                                      children: JsxRuntime.jsx(ReviewCard.make, {
                                            bodyText: "Really like being able to sms a birthday video as well as the $ in gift cards. It ensures that the birthday person thinks about what they really want and buys it.",
                                            titleText: "Quick and easy present giving!"
                                          })
                                    }),
                                JsxRuntime.jsx(React.SwiperSlide, {
                                      children: Util.ReactStuff.s("")
                                    })
                              ],
                              className: swiperContainer,
                              grabCursor: true,
                              slidesPerView: 10
                            })
                      ],
                      className: wrapper
                    })
              ],
              className: "relative"
            });
}

var make = AppsReviewSection;

var $$default = AppsReviewSection;

export {
  swiperBreakpoints ,
  Styles ,
  make ,
  $$default ,
  $$default as default,
}
/* swiperBreakpoints Not a pure module */
