// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util from "../../../common/util.bs.js";
import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as StaticImage from "../../StaticImage.bs.js";
import * as DownloadAppleApp from "../../library/DownloadAppleApp.bs.js";
import * as DownloadGoogleApp from "../../library/DownloadGoogleApp.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

var container = Curry._1(BsCss.style, {
      hd: BsCss.backgroundImage({
            NAME: "url",
            VAL: "/images/downloadApps/downloadTheApp_backgroud.png"
          }),
      tl: {
        hd: BsCss.backgroundRepeat("noRepeat"),
        tl: {
          hd: BsCss.backgroundPosition("top"),
          tl: {
            hd: BsCss.backgroundSize("cover"),
            tl: {
              hd: BsCss.position("relative"),
              tl: {
                hd: BsCss.backgroundSize({
                      NAME: "size",
                      VAL: [
                        BsCss.pct(100),
                        BsCss.pct(45)
                      ]
                    }),
                tl: {
                  hd: BsCss.media("(min-width: 768px) and (max-width: 1023px)", {
                        hd: BsCss.backgroundSize({
                              NAME: "size",
                              VAL: [
                                BsCss.pct(100),
                                BsCss.pct(55)
                              ]
                            }),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: BsCss.media("(min-width: 1024px)", {
                          hd: BsCss.backgroundSize({
                                NAME: "size",
                                VAL: [
                                  BsCss.pct(100),
                                  BsCss.pct(70)
                                ]
                              }),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var innerContainer = Curry._1(BsCss.style, {
      hd: BsCss.paddingTop(BsCss.px(40)),
      tl: {
        hd: BsCss.media("(min-width: 768px) and (max-width: 1023px)", {
              hd: BsCss.paddingTop(BsCss.px(89)),
              tl: /* [] */0
            }),
        tl: {
          hd: BsCss.media("(min-width: 1024px)", {
                hd: BsCss.display("flex"),
                tl: {
                  hd: BsCss.justifyItems("center"),
                  tl: {
                    hd: BsCss.justifyContent("center"),
                    tl: {
                      hd: BsCss.paddingTop(BsCss.px(39)),
                      tl: /* [] */0
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      }
    });

var leftContainer = Curry._1(BsCss.style, {
      hd: BsCss.flex({
            NAME: "num",
            VAL: 1.0
          }),
      tl: {
        hd: BsCss.marginTop("auto"),
        tl: {
          hd: BsCss.marginBottom("auto"),
          tl: /* [] */0
        }
      }
    });

var rightContainer = Curry._1(BsCss.style, {
      hd: BsCss.flex({
            NAME: "num",
            VAL: 1.0
          }),
      tl: {
        hd: BsCss.display("flex"),
        tl: {
          hd: BsCss.flexDirection("column"),
          tl: {
            hd: BsCss.justifyContent("spaceEvenly"),
            tl: /* [] */0
          }
        }
      }
    });

var mobileTextContainer = Curry._1(BsCss.style, {
      hd: BsCss.textAlign("center"),
      tl: {
        hd: BsCss.media("(min-width: 1024px)", {
              hd: BsCss.display("none"),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var desktopTextContainer = Curry._1(BsCss.style, {
      hd: BsCss.display("none"),
      tl: {
        hd: BsCss.media("(min-width: 1024px)", {
              hd: BsCss.display("block"),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var headerText = Curry._1(BsCss.style, {
      hd: BsCss.position("relative"),
      tl: {
        hd: BsCss.color(BsCss.hex("FFFFFF")),
        tl: {
          hd: BsCss.fontSize(BsCss.px(32)),
          tl: {
            hd: BsCss.fontWeight({
                  NAME: "num",
                  VAL: 600
                }),
            tl: {
              hd: BsCss.lineHeight(BsCss.px(40)),
              tl: {
                hd: BsCss.paddingBottom(BsCss.px(16)),
                tl: {
                  hd: BsCss.media("(min-width: 768px) and (max-width: 1023px)", {
                        hd: BsCss.paddingBottom(BsCss.px(16)),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var contentText = Curry._1(BsCss.style, {
      hd: BsCss.position("relative"),
      tl: {
        hd: BsCss.color(BsCss.hex("FFFFFF")),
        tl: {
          hd: BsCss.fontSize(BsCss.px(16)),
          tl: {
            hd: BsCss.fontWeight({
                  NAME: "num",
                  VAL: 400
                }),
            tl: {
              hd: BsCss.lineHeight(BsCss.px(22)),
              tl: {
                hd: BsCss.paddingBottom(BsCss.px(38)),
                tl: {
                  hd: BsCss.width(BsCss.pct(70.0)),
                  tl: {
                    hd: BsCss.marginLeft("auto"),
                    tl: {
                      hd: BsCss.marginRight("auto"),
                      tl: {
                        hd: BsCss.media("(min-width: 768px) and (max-width: 1023px)", {
                              hd: BsCss.width(BsCss.pct(50.0)),
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: BsCss.media("(min-width: 1024px)", {
                                hd: BsCss.marginLeft("zero"),
                                tl: {
                                  hd: BsCss.marginRight("zero"),
                                  tl: /* [] */0
                                }
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var img = Curry._1(BsCss.style, {
      hd: BsCss.width(BsCss.pct(80)),
      tl: {
        hd: BsCss.marginLeft("auto"),
        tl: {
          hd: BsCss.marginRight("auto"),
          tl: {
            hd: BsCss.media("(min-width: 768px) and (max-width: 1023px)", {
                  hd: BsCss.width(BsCss.pct(60.0)),
                  tl: /* [] */0
                }),
            tl: {
              hd: BsCss.media("(min-width: 1024px)", {
                    hd: BsCss.width(BsCss.pct(70)),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var subTitleText = Curry._1(BsCss.style, {
      hd: BsCss.position("relative"),
      tl: {
        hd: BsCss.color(BsCss.hex("000")),
        tl: {
          hd: BsCss.fontSize(BsCss.px(32)),
          tl: {
            hd: BsCss.fontWeight({
                  NAME: "num",
                  VAL: 600
                }),
            tl: {
              hd: BsCss.lineHeight(BsCss.px(40)),
              tl: {
                hd: BsCss.paddingBottom(BsCss.px(24)),
                tl: {
                  hd: BsCss.textAlign("center"),
                  tl: {
                    hd: BsCss.media("(min-width: 1024px)", {
                          hd: BsCss.textAlign("left"),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var appsDiv = Curry._1(BsCss.style, {
      hd: BsCss.marginTop(BsCss.px(50)),
      tl: {
        hd: BsCss.marginBottom(BsCss.px(50)),
        tl: {
          hd: BsCss.media("(min-width: 1024px)", {
                hd: BsCss.marginTop(BsCss.px(100)),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var appsInnerDiv = Curry._1(BsCss.style, {
      hd: BsCss.display("flex"),
      tl: {
        hd: BsCss.justifyContent("center"),
        tl: {
          hd: BsCss.media("(min-width: 1024px)", {
                hd: BsCss.justifyContent("flexStart"),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var appsLogo = Curry._1(BsCss.style, {
      hd: BsCss.width(BsCss.px(158)),
      tl: {
        hd: BsCss.marginRight(BsCss.px(20)),
        tl: /* [] */0
      }
    });

var Styles = {
  container: container,
  innerContainer: innerContainer,
  leftContainer: leftContainer,
  rightContainer: rightContainer,
  mobileTextContainer: mobileTextContainer,
  desktopTextContainer: desktopTextContainer,
  headerText: headerText,
  contentText: contentText,
  img: img,
  subTitleText: subTitleText,
  appsDiv: appsDiv,
  appsInnerDiv: appsInnerDiv,
  appsLogo: appsLogo
};

function AppsHeaderSection(props) {
  var match = Util.EnvUtil.currentCountry;
  var tmp;
  if (match.TAG === /* Ok */0) {
    switch (match._0) {
      case /* GreatBritain */1 :
          tmp = "/images/downloadApps/downloadTheApp_HeaderPhone_UK.png";
          break;
      case /* UnitedStates */2 :
          tmp = "/images/downloadApps/downloadTheApp_HeaderPhone_US.png";
          break;
      case /* NewZealand */3 :
          tmp = "/images/downloadApps/downloadTheApp_HeaderPhone_NZ.png";
          break;
      case /* Australia */0 :
      case /* Canada */4 :
          tmp = "/images/downloadApps/downloadTheApp_HeaderPhone.png";
          break;
      
    }
  } else {
    tmp = "/images/downloadApps/downloadTheApp_HeaderPhone.png";
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("h1", {
                                            children: Util.ReactStuff.s("Never lose a gift card again! "),
                                            className: headerText
                                          }),
                                      JsxRuntime.jsx("h3", {
                                            children: Util.ReactStuff.s("Store eGift cards in your Prezzee Wallet on the free Prezzee App!"),
                                            className: contentText
                                          })
                                    ],
                                    className: mobileTextContainer
                                  }),
                              JsxRuntime.jsx(StaticImage.make, {
                                    src: tmp,
                                    alt: "Prezzee download apps header image",
                                    width: 667,
                                    height: 733,
                                    className: img
                                  })
                            ],
                            className: leftContainer
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("h1", {
                                            children: Util.ReactStuff.s("Never lose a gift card again! "),
                                            className: headerText
                                          }),
                                      JsxRuntime.jsx("h3", {
                                            children: Util.ReactStuff.s("Store eGift cards in your Prezzee Wallet on the free Prezzee App!"),
                                            className: contentText
                                          })
                                    ],
                                    className: desktopTextContainer
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("h2", {
                                            children: Util.ReactStuff.s("Download now!"),
                                            className: subTitleText
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(DownloadAppleApp.make, {
                                                    className: appsLogo
                                                  }),
                                              JsxRuntime.jsx(DownloadGoogleApp.make, {
                                                    className: appsLogo
                                                  })
                                            ],
                                            className: appsInnerDiv
                                          })
                                    ],
                                    className: appsDiv
                                  })
                            ],
                            className: rightContainer
                          })
                    ],
                    className: innerContainer
                  }),
              className: container
            });
}

var make = AppsHeaderSection;

var $$default = AppsHeaderSection;

export {
  Styles ,
  make ,
  $$default ,
  $$default as default,
}
/* container Not a pure module */
