// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Constants from "../../../common/Constants.bs.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as AppsHeaderSection from "./AppsHeaderSection.bs.js";
import * as AppsReviewSection from "./AppsReviewSection.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as AppsFeatureSection from "./AppsFeatureSection.bs.js";

function DownloadApps(props) {
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(AppsHeaderSection.make, {}),
                JsxRuntime.jsx(AppsReviewSection.make, {}),
                JsxRuntime.jsx(AppsFeatureSection.make, {})
              ]
            });
}

function getServerSideProps(param) {
  if (Belt_Option.isNone(Js_dict.get(param.query, "preview"))) {
    param.res.setHeader("Cache-Control", Constants.ssr_page_cache);
  }
  return {
          props: {}
        };
}

var make = DownloadApps;

var $$default = DownloadApps;

export {
  make ,
  getServerSideProps ,
  $$default ,
  $$default as default,
}
/* AppsHeaderSection Not a pure module */
