// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util from "../../../common/util.bs.js";
import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Theme from "../../../css/Theme.bs.js";
import * as FeatureItem from "./FeatureItem.bs.js";
import * as StaticImage from "../../StaticImage.bs.js";
import * as DownloadAppleApp from "../../library/DownloadAppleApp.bs.js";
import * as DownloadGoogleApp from "../../library/DownloadGoogleApp.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

var container = Curry._1(BsCss.style, {
      hd: BsCss.backgroundColor(Theme.Color.paleGrey),
      tl: {
        hd: BsCss.media("(min-width: 1024px)", {
              hd: BsCss.paddingTop(BsCss.px(30)),
              tl: {
                hd: BsCss.paddingLeft(BsCss.px(30)),
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    });

var leftContainer = Curry._1(BsCss.style, {
      hd: BsCss.media("(min-width: 1414px)", {
            hd: BsCss.width(BsCss.pct(50)),
            tl: {
              hd: BsCss.paddingLeft(BsCss.px(80)),
              tl: /* [] */0
            }
          }),
      tl: /* [] */0
    });

var leftInnerContainer = Curry._1(BsCss.style, {
      hd: BsCss.marginLeft(BsCss.px(20)),
      tl: {
        hd: BsCss.marginBottom(BsCss.px(20)),
        tl: {
          hd: BsCss.media("(min-width: 768px) and (max-width: 1023px)", {
                hd: BsCss.marginLeft("auto"),
                tl: {
                  hd: BsCss.display("flex"),
                  tl: {
                    hd: BsCss.width(BsCss.pct(90)),
                    tl: {
                      hd: BsCss.marginRight("auto"),
                      tl: {
                        hd: BsCss.justifyContent("spaceAround"),
                        tl: {
                          hd: BsCss.paddingTop(BsCss.px(10)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }),
          tl: {
            hd: BsCss.media("(min-width: 1024px) and (max-width: 1413px)", {
                  hd: BsCss.display("flex"),
                  tl: {
                    hd: BsCss.justifyContent("spaceBetween"),
                    tl: {
                      hd: BsCss.marginLeft("zero"),
                      tl: /* [] */0
                    }
                  }
                }),
            tl: {
              hd: BsCss.media("(min-width: 1414px)", {
                    hd: BsCss.display("flex"),
                    tl: {
                      hd: BsCss.justifyContent("spaceBetween"),
                      tl: {
                        hd: BsCss.marginLeft("zero"),
                        tl: {
                          hd: BsCss.width(BsCss.pct(70)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var appsInnerDiv = Curry._1(BsCss.style, {
      hd: BsCss.display("flex"),
      tl: {
        hd: BsCss.justifyContent("center"),
        tl: {
          hd: BsCss.media("(min-width: 1024px)", {
                hd: BsCss.justifyContent("flexStart"),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var appsLogo = Curry._1(BsCss.style, {
      hd: BsCss.width(BsCss.px(158)),
      tl: {
        hd: BsCss.marginRight(BsCss.px(20)),
        tl: /* [] */0
      }
    });

var subTitleText = Curry._1(BsCss.style, {
      hd: BsCss.position("relative"),
      tl: {
        hd: BsCss.color(BsCss.hex("000")),
        tl: {
          hd: BsCss.fontSize(BsCss.px(32)),
          tl: {
            hd: BsCss.fontWeight({
                  NAME: "num",
                  VAL: 600
                }),
            tl: {
              hd: BsCss.lineHeight(BsCss.px(40)),
              tl: {
                hd: BsCss.paddingBottom(BsCss.px(24)),
                tl: {
                  hd: BsCss.textAlign("center"),
                  tl: {
                    hd: BsCss.media("(min-width: 1024px)", {
                          hd: BsCss.textAlign("left"),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var phoneHand = Curry._1(BsCss.style, {
      hd: BsCss.width(BsCss.pct(100)),
      tl: {
        hd: BsCss.media("(min-width: 768px) and (max-width: 1023px)", {
              hd: BsCss.display("none"),
              tl: /* [] */0
            }),
        tl: {
          hd: BsCss.media("(min-width: 1024px) and (max-width: 1413px)", {
                hd: BsCss.width(BsCss.pct(50)),
                tl: /* [] */0
              }),
          tl: {
            hd: BsCss.media("(min-width: 1414px)", {
                  hd: BsCss.width(BsCss.pct(45)),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var phoneHandMd = Curry._1(BsCss.style, {
      hd: BsCss.display("none"),
      tl: {
        hd: BsCss.media("(min-width: 768px) and (max-width: 1023px)", {
              hd: BsCss.display("block"),
              tl: {
                hd: BsCss.width(BsCss.pct(60.0)),
                tl: {
                  hd: BsCss.overflow("auto"),
                  tl: /* [] */0
                }
              }
            }),
        tl: /* [] */0
      }
    });

var bodyText = Curry._1(BsCss.style, {
      hd: Theme.Typography.defaultFonts,
      tl: {
        hd: BsCss.fontSize(BsCss.px(14)),
        tl: {
          hd: BsCss.lineHeight(BsCss.px(20)),
          tl: {
            hd: BsCss.color(Theme.Color.midGrey),
            tl: {
              hd: BsCss.fontWeight("normal"),
              tl: {
                hd: BsCss.fontStyle("normal"),
                tl: {
                  hd: BsCss.marginLeft(BsCss.px(20)),
                  tl: {
                    hd: BsCss.marginRight(BsCss.px(20)),
                    tl: {
                      hd: BsCss.paddingTop(BsCss.px(15)),
                      tl: {
                        hd: BsCss.paddingBottom(BsCss.px(15)),
                        tl: {
                          hd: BsCss.media("(min-width: 768px) and (max-width: 1279px)", {
                                hd: BsCss.marginLeft(BsCss.px(30)),
                                tl: /* [] */0
                              }),
                          tl: {
                            hd: BsCss.media("(min-width: 1280px) and (max-width: 1449px)", {
                                  hd: BsCss.marginLeft(BsCss.px(60)),
                                  tl: /* [] */0
                                }),
                            tl: {
                              hd: BsCss.media("(min-width: 1450px)", {
                                    hd: BsCss.marginLeft(BsCss.px(60)),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var Styles = {
  container: container,
  leftContainer: leftContainer,
  leftInnerContainer: leftInnerContainer,
  appsInnerDiv: appsInnerDiv,
  appsLogo: appsLogo,
  subTitleText: subTitleText,
  phoneHand: phoneHand,
  phoneHandMd: phoneHandMd,
  bodyText: bodyText
};

function AppsFeatureSection(props) {
  var match = Util.EnvUtil.currentCountry;
  var tmp;
  tmp = match.TAG === /* Ok */0 && match._0 === 2 ? "/images/downloadApps/downloadTheApp_PhoneHand_us.png" : "/images/downloadApps/downloadTheApp_PhoneHand.png";
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx(FeatureItem.make, {
                                                            bodyText: "Easily buy, send, store and redeem your gift cards",
                                                            iconSrc: "/images/icon_no_fees.svg",
                                                            iconDescription: "icon_no_fees"
                                                          }),
                                                      JsxRuntime.jsx(FeatureItem.make, {
                                                            bodyText: Util.EnvUtil.countryCode === "US" ? "Send eGift cards with a personalized video message" : "Send eGift cards with a personalised video message",
                                                            iconSrc: "/images/icon_laptop_gift.svg",
                                                            iconDescription: "icon_laptop_gift"
                                                          })
                                                    ]
                                                  }),
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx(FeatureItem.make, {
                                                            bodyText: "In-app support via email and phone",
                                                            iconSrc: "/images/icon_customer_service.svg",
                                                            iconDescription: "icon_customer_service"
                                                          }),
                                                      JsxRuntime.jsx(FeatureItem.make, {
                                                            bodyText: "Check your gift card balances in real time*",
                                                            iconSrc: "/images/icon_store_in_wallet.svg",
                                                            iconDescription: "icon_store_in_wallet"
                                                          })
                                                    ]
                                                  })
                                            ],
                                            className: leftInnerContainer
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx("h2", {
                                                            children: Util.ReactStuff.s("Get it now for iOS and Android"),
                                                            className: subTitleText
                                                          }),
                                                      JsxRuntime.jsxs("div", {
                                                            children: [
                                                              JsxRuntime.jsx(DownloadAppleApp.make, {
                                                                    className: appsLogo
                                                                  }),
                                                              JsxRuntime.jsx(DownloadGoogleApp.make, {
                                                                    className: appsLogo
                                                                  })
                                                            ],
                                                            className: appsInnerDiv
                                                          })
                                                    ]
                                                  }),
                                              JsxRuntime.jsx(StaticImage.make, {
                                                    src: tmp,
                                                    alt: "downloadTheApp_PhoneHand",
                                                    width: 844,
                                                    height: 621,
                                                    className: phoneHandMd
                                                  })
                                            ],
                                            className: "md:flex items-center"
                                          })
                                    ],
                                    className: leftContainer
                                  }),
                              JsxRuntime.jsx(StaticImage.make, {
                                    src: tmp,
                                    alt: "downloadTheApp_PhoneHand",
                                    width: 844,
                                    height: 621,
                                    className: phoneHand
                                  })
                            ],
                            className: "lg:flex justify-between items-center"
                          }),
                      className: container
                    }),
                JsxRuntime.jsx("p", {
                      children: Util.ReactStuff.s("*Always available for Prezzee Smart eGift Cards. Live balances for retailers are dependent on the individual retailer  providing that information"),
                      className: bodyText
                    })
              ]
            });
}

var make = AppsFeatureSection;

var $$default = AppsFeatureSection;

export {
  Styles ,
  make ,
  $$default ,
  $$default as default,
}
/* container Not a pure module */
