// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util from "../../../common/util.bs.js";
import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Theme from "../../../css/Theme.bs.js";
import * as StaticImage from "../../StaticImage.bs.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

var container = Curry._1(BsCss.style, {
      hd: BsCss.backgroundImage({
            NAME: "url",
            VAL: "/images/downloadApps/quote_bg.svg"
          }),
      tl: {
        hd: BsCss.backgroundRepeat("noRepeat"),
        tl: {
          hd: BsCss.backgroundSize("auto"),
          tl: {
            hd: BsCss.position("relative"),
            tl: {
              hd: BsCss.width(BsCss.px(288)),
              tl: {
                hd: BsCss.height(BsCss.px(298)),
                tl: {
                  hd: BsCss.borderRadius(BsCss.px(8)),
                  tl: {
                    hd: BsCss.boxShadow(Css_Legacy_Core.Shadow.box(BsCss.px(4), BsCss.px(4), BsCss.px(25), undefined, undefined, BsCss.rgba(68, 68, 68, BsCss.pct(15.0)))),
                    tl: {
                      hd: BsCss.backgroundColor(Theme.Color.white),
                      tl: {
                        hd: BsCss.padding(BsCss.px(20)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var titleText = Curry._1(BsCss.style, {
      hd: BsCss.position("relative"),
      tl: {
        hd: BsCss.color(BsCss.hex("444")),
        tl: {
          hd: BsCss.fontSize(BsCss.px(18)),
          tl: {
            hd: BsCss.fontWeight({
                  NAME: "num",
                  VAL: 500
                }),
            tl: {
              hd: BsCss.lineHeight(BsCss.px(25)),
              tl: {
                hd: BsCss.paddingTop(BsCss.px(12)),
                tl: {
                  hd: BsCss.paddingBottom(BsCss.px(12)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var bodyText = Curry._1(BsCss.style, {
      hd: Theme.Typography.defaultFonts,
      tl: {
        hd: BsCss.fontSize(BsCss.px(16)),
        tl: {
          hd: BsCss.lineHeight(BsCss.px(24)),
          tl: {
            hd: BsCss.color(Theme.Color.midGrey),
            tl: {
              hd: BsCss.fontWeight("normal"),
              tl: {
                hd: BsCss.fontStyle("normal"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var img = Curry._1(BsCss.style, {
      hd: BsCss.width(BsCss.px(87)),
      tl: /* [] */0
    });

var Styles = {
  container: container,
  titleText: titleText,
  bodyText: bodyText,
  img: img
};

function ReviewCard(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(StaticImage.make, {
                      src: "/images/downloadApps/review_stars.svg",
                      alt: "review stars",
                      width: 87,
                      height: 20,
                      className: img
                    }),
                JsxRuntime.jsx("h3", {
                      children: Util.ReactStuff.s(props.titleText),
                      className: titleText
                    }),
                JsxRuntime.jsx("h4", {
                      children: Util.ReactStuff.s(props.bodyText),
                      className: bodyText
                    })
              ]
            });
}

var make = ReviewCard;

export {
  Styles ,
  make ,
}
/* container Not a pure module */
